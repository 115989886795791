export const defaultTips = {
	ToAccounts: `Go to accounts details page`,
	BU: ` Name of the BU`,
	BE: `Name of the BE`,
	OrgId: `Organization ID`,
	Scope: ` Scope ID`,
	Subscope: ` Sub-scope ID`,
	NbAccount: `Number of accounts`,
	AccountId: `Account ID`,
	AccountAlias: `Alias of the account `,
	Score: `<strong>Aim</strong> : Evaluated a global score for the BE (lower the score is, better it is)`,
	MFA: `
		<strong>Aim :</strong> Root account connection attempts are NOT logged;
		<strong>MFA</strong> is the only way to secure root access. Mandatory in AWS contract (MAR).</br>
		<strong>KPI Rule :</strong> Root account access must be secured by a hard token (Gemalto or Yubikey for instance). </br>
		<strong>Risk :</strong> Data leak / All infrastructure are under risk (should be deleted) / Access on premise infrastructure/data through Direct Connect link</br>
		<strong>Expected value :</strong> Global View : 100% / Detailed View : 1(&#10004;)</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">MFA</span>
`,
	CT: `				
		<strong>Aim :</strong> Log all AWS managed services call API. Must be enabled on every region and stored on a centralized S3 Bucket to ensure seggregation</br>
		<strong>KPI Rule :</strong> Now configured centrally for all accounts, so KPI should always be 100% compliant</br>
		<strong>Expected value :</strong> Global View :100% / Detailed View : 1(&#10004;)</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CT</span>
`,
	TA: `
		<strong>Aim :</strong> Provides weekly report on Cost Optimization / Performance / Security / Fault Tolerance / Service Limits. 
		MFA on root account and CloudTrail activation are checked by Trusted Advisor(with more than 100 other items)</br>
		<strong>KPI Rule :</strong> Reports received every Wednesday morning; this report must be received at least by: CERT / GSOC / CCoE. 
		It’s also recommended to add BE / BU CISO and operational team in the distribution list.</br>
		<strong>Risk :</strong> Lack of monitoring / difficulties to follow KPIs and Minimum Architecture Requirements (AWS MAR)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100% / Detailed View : 1(&#10004;)</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">TA</span>
`,
	IAM: `
		<strong>AWS identity and Access Management (IAM)</strong> enables you to manage access to AWS services and resources securely.</br> 
		<p>Using IAM, AWS users and groups and use permissions can be created and managed to allow and deny their access to AWS resources.</p>
		<p>All ENGIE’s AWS Accounts are federated to OKTA B2E for authentication. AWS management shall only be done by Personal Administration Account (PAA)</p>
		<p>The AWS Group security standard doesn’t enable account owner to create local personal user in AWS IAM.</br>
		It’s only allowed to create service account if there’s no alternative solution (for example by using assume
		role).</p>
`,
	IAMUSER: `
		<strong>Aim :</strong> Number of users created in the AWS IAM service. As usage of this service is prohibited in the Group security standard the KPI should be 0.</br> 
		<strong>KPI Rule :</strong>For each AWS account: sum(local user expect local user declared as service account). For BE :sum(value for each AWS account)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0 / Detailed View : 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:User</span>
`,
	IAMADMIN: `
		<strong>Aim :</strong> Check if local user has administration rights</br>
		<strong>KPI Rule :</strong>For each local user : 1 if “Administrator Access” Policy</br>
		For each AWS account: sum(value for each local user)</br>For BE : sum(value for each AWS account).</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0 / Detailed View : 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:Admin</span>
`,
	IAMAGE: `
		<strong>Aim :</strong> Access key shall be rotated at least every 90 days</br>
		<strong>KPI Rule :</strong>For each local user : int(key age/90)</br>
		For each AWS account: sum(value for each local user)</br>For BE : sum(value for each AWS account).</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0 / Detailed View : 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:Age</span>
`,
	IAMSCORE: `
		<strong>Aim :</strong> Evaluated a global score for the BE (lower the score is, better it is)</br>
		<strong>KPI Rule :</strong>For each local user : (Key>90 score + Admin score) * factor / Factor =
		1.5 if key>90 AND Admin Rights / Factor = 1 otherwise</br>For each AWS account: sum (value for each local user)</br>
		For BE : sum(value for each AWS account)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0 / Detailed View : 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:Score</span>
`,
	IAMNOBOUNDARY: `
		<strong>Aim :</strong> User with IAM access should have boundary</br>
		<strong>KPI Rule :</strong>For each AWS account : sum(value for each local user)</br>
		For BE : %(value for each AWS account).</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> BOUNDARY</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:Boundary</span>
`,
	IAMSVCCONSOLE: `
		<strong>Aim :</strong> Service should not have access to AWS console</br>
		<strong>KPI Rule :</strong>For each AWS account : sum(value for each local user)</br>
		For BE : %(value for each AWS account).</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SVCCONSOLE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">IAM:SvcConsole</span>
`,
	IAMSVC: `total`,
	S3: `
		Simple Storage Service : Encryption & Visibility
`,
	S3Total: `
		Number of buckets
`,
	S3Encrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong>For BE : percentage of encrypted S3 buckets</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:Encryption</span>
`,
	S3UnEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of unencrypted S3 buckets</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:Encryption</span>
`,
	S3EncryptionInTransit: `
		<strong>Aim :</strong> Bucket policies must enforce proper encryption in transit (using conditions aws:SecureTransport and s3:TlsVersion >= 1.2)</br>
		<strong>KPI Rule :</strong>For each AWS account & BE : number of buckets allowing unencrypted or weakly encrypted requests</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:EncryptionInTransit</span>
`,
	S3Public: `
		<strong>Aim :</strong> S3 Should not be open to public, they can be accessible if they have a policy that allow
		ONLY Cloudfront access.</br>
		<strong>KPI Rule :</strong> For each AWS account & BE: number of public S3 buckets</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:Public</span>
`,
	S3AccessPointPublic: `
		<strong>Aim :</strong> S3 Should not be open to public, they can be accessible if they have a policy that allow
		ONLY Cloudfront access.</br>
		<strong>KPI Rule :</strong> For each AWS account: number of public S3 Access Point</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View: 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3AccessPoint:Public</span>
`,
	S3CanBePublic: `
		<strong>Aim :</strong> S3 Should not be open to public, they can be accessible if they have a policy that allow
		ONLY Cloudfront access.</br>
		<strong>KPI Rule :</strong> For each AWS account: number of public S3 without explicit deny public access</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View: 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:CanBePublic</span>
`,
	S3AccessPointCanBePublic: `
		<strong>Aim :</strong> S3 Should not be open to public, they can be accessible if they have a policy that allow
		ONLY Cloudfront access.</br>
		<strong>KPI Rule :</strong> For each AWS account: number of public S3 Access Point without explicit deny public access</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View: 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3AccessPoint:CanBePublic</span>
`,
	S3AccessPoint: `
		<strong>Aim :</strong> S3 AccessPoint Should not be open to public.</br>
		<strong>KPI Rule :</strong>BE: number of potentially public S3 buckets</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">S3:CanBePublic</span>
	`,
	S3AccessPointTotal: `
		Number of S3 access points 
	`,
	RDS: `
		Relational Database Service : Encryption & Visibility
	`,
	RDSTotal: `
		Relational Database Service : Number of RDS
	`,
	RDSPublic: `
		<strong>Aim :</strong> RDS Should not be open to public</br>
		<strong>KPI Rule :</strong> For each AWS account & BE: number of public RDS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">RDS:Public</span>
	`,
	RDSEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For BE : percentage of encrypted RDS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">RDS:Encryption</span>
	`,
	RDSUnEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of unencrypted RDS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">RDS:Encryption</span>
	`,
	EFS: `
		Elastic File System : Encryption
	`,
	EFSEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For BE : percentage of encrypted EFS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EFS:Encryption</span>
	`,
	EFSTotal: `
		Elastic File System : Number of EFS
	`,
	EFSUnEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of encrypted EFS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EFS:Encryption</span>
	`,
	EBS: `
		Elastic Block Store : Encryption
	`,
	EBSEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For BE : percentage of encrypted EBS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EBS:Encryption</span>
	`,
	EBSTotal: `
		Elastic Block Store : Encryption
	`,
	EBSUnEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of encrypted EBS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EBS:Encryption</span>
	`,
	FSX: `
		FSx for Windows File Server : Encryption
	`,
	FSXEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For BE : percentage of encrypted FSX</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED
	`,
	FSXTotal: `
		FSx for Windows File Server : Number of FSX
	`,
	FSXUnEncrypted: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of encrypted FSX</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">FSX:Encryption</span>
	`,
	NatGateway: `
		NAT Gateways
	`,
	NatGatewayTotal: `
		<strong>Aim :</strong> Outgoing flows must be logged and filtered. NAT GW is not compliant with this two security rules;</br>
		<strong>New Remediation :</strong>Use a network firewall with your NatGateway</br>
		<strong>KPI Rule :</strong> For each AWS account & BE: number of NAT GW</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : x / Detailed View : x</span></br>
		<strong>Exception:</strong> IGNORE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:*</span>
	`,
	NatGatewayNETWORKFIREWALLNotAttached: `
		<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
		<strong>KPI Rule :</strong> For each AWS account number of NAT of NAT GW without a Network Firewall attached</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> FIREWALL or IGNORE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:NotAttached</span>
	`,
	NatGatewayNETWORKFIREWALLBadNetworkFirewall: `
		<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
		This Network Firewall must have logging <strong>enabled</strong> and <strong>deny</strong> any any traffic</br>
		<strong>KPI Rule :</strong> For each AWS account number of NAT of Network Firewall attached that are badly configured</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> FIREWALL or IGNORE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:BadNetworkFirewall</span>
	`,
	NatGatewayNetworkFirewallNotAttached: `
		<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
		<strong>KPI Rule :</strong> For each BE: number of NAT of NAT GW without a Network Firewall attached</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x)</span></br>
		<strong>Exception:</strong> FIREWALL or IGNORE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:NotAttached</span>
	`,
	NatGatewayBadNetworkFirewall: `
		<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
		This Network Firewall must have logging <strong>enabled</strong> and <strong>deny</strong> any any traffic</br>
		<strong>KPI Rule :</strong> For each BE: number of NAT of Network Firewall attached that are badly configured</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x)</span></br>
		<strong>Exception:</strong> FIREWALL or IGNORE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:BadNetworkFirewall</span>
	`,
	// NatGatewayNetworkFirewallAttached: `
	// 	<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
	// 	<strong>KPI Rule :</strong> For each BE: percentage of NAT GW with a Network Firewall attached</br>
	// 	<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
	// 	<strong>Exception:</strong> FIREWALL</br>
	// 	<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:NotAttached</span>
	// `,
	// NatGatewayGoodNetworkFirewall: `
	// 	<strong>Aim :</strong> Every NATGW should be attached to a Network Firewall</br>
	// 	This Network Firewall must have logging <strong>enabled</strong> and <strong>deny</strong> any any traffic</br>
	// 	<strong>KPI Rule :</strong> For each BE: percentage of Network Firewall attached that are well configured</br>
	// 	<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
	// 	<strong>Exception:</strong> FIREWALL</br>
	// 	<strong>Compliant Name :</strong> <span class="KPIDefault">NATGW:NETWORKFIREWALL:BadNetworkFirewall</span>
	// `,
	EC2: `
		Elastic Compute Cloud : Visibility, Access, Inpection
	`,
	EC2Total: `
		Elastic Compute Cloud : Number of EC2 instances
	`,
	EC2AutoscaledVMs: `
		Elastic Compute Cloud : Number of EC2 instances that are managed by an Auto Scaled Group
	`,
	EC2BadInspector: `
		<strong>Aim :</strong> Inspector v2 or v1 should run on every instance, and last run should not be older than 30days (for v1)</br>
		<strong>KPI Rule :</strong> For BE : percentage of EC2 with neither Inspector v2 or v1</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:BadInspector</span>
	`,
	EC2Public: `
		<strong>Aim :</strong> EC2 instance should not have a public IP address except in some use case like proxy or firewall for example</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with public IP address / For BE : number of EC2 with public IP address</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Public</span>
	`,
	EC2MetadataIMDSv2NotEnforced: `
		<strong>Aim :</strong> EC2 instance should use only IMDSv2, IMDSv1 should be disabled</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with IMDSv2 not enforced / For BE : number of EC2 with IMDSv2 not enforced</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> IMDSv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Metadata:IMDSv2NotEnforced</span>
	`,
	EC2SSMAgent: `
		<strong>Aim :</strong> EC2 instance should have an up-to-date SSM agent properly installed and configured</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 not managed by SSM / For BE : % of EC2 not managed by SSM</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x%) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSMAGENT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSMAgent</span>
	`,
	EC2SSMAgentNotInstalled: `
		<strong>Aim :</strong> EC2 instance should have an up-to-date SSM agent properly installed and configured</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with SSM agent not installed</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSMAGENT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSMAgent:NotInstalled</span>
	`,
	EC2SSMAgentNotReachable: `
		<strong>Aim :</strong> EC2 instance should have an up-to-date SSM agent properly installed and configured</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with SSM agent not reachable</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSMAGENT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSMAgent:NotReachable</span>
	`,
	EC2SSMAgentNotUptodate: `
		<strong>Aim :</strong> EC2 instance should have an up-to-date SSM agent properly installed and configured</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with SSM agent not up to date (>2.0.533)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSMAGENT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSMAgent:NotUptodate</span>
	`,
	EC2Permissions: `
		<strong>Aim :</strong> EC2 instance profile should be configured with as little privilege as possible</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with admin or power user rights / For BE : % of EC2 with admin or power user rights</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x%) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> EC2PERMISSIONS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Permissions</span>
	`,
	EC2PermissionsAdmin: `
		<strong>Aim :</strong> EC2 instance profile should be configured with as little privilege as possible</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with admin rights</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> EC2PERMISSIONS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Permissions:Admin</span>
	`,
	EC2PermissionsPowerUser: `
		<strong>Aim :</strong> EC2 instance profile should be configured with as little privilege as possible</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with power user rights</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> EC2PERMISSIONS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Permissions:PowerUser</span>
	`,
	EC2SG00_Public: `
		<strong>Aim :</strong> Security Groupe should not have flows open from the Internet without IP addresses restriction (except for some usage)</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with public IP (any protocol/port) from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SGPUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SG00_Public</span>
	`,
	EC2SG00_Private: `
		<strong>Aim :</strong> Security Groupe should not have flows open from the private LAN without IP addresses restriction (except for some usage / at least 10.0.0.0/8)</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with private IP (any protocol/port) from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SGPRIVATE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SG00_Private</span>
	`,
	EC2SSHRDP_Private: `
		<strong>Aim :</strong> SSH and RDP flows should not be open on the private LAN without at least IP restriction (at least 10.0.0.0/8)</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with private IP and TCP22&3389 from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSHPRIVATE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSHRDP_Private</span>
	`,
	EC2SSHRDP_Public: `
		<strong>Aim :</strong> SSH and RDP flows should not be open on the Internet without at least IP restriction</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with public IP and TCP22&3389 from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SSHPUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:SSHRDP_Public</span>
	`,
	EC2UNWANTEDPORTS_Private: `
		<strong>Aim :</strong> SSH, RDP, Netbios, SMB, Telnet, SNMP flows should not be open on the private LAN without at least IP restriction (at least 10.0.0.0/8)</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with private IP and TCP in [22, 3389, 137, 138, 139, 445, 23, 161, 162] from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNWANTEDPORTSPRIVATEWORKSPACES:EncryptionUser</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:UNWANTEDPORTS_Private</span>
	`,
	EC2UNWANTEDPORTS_Public: `
		<strong>Aim :</strong> SSH, RDP, Netbios, SMB, Telnet, SNMP flows should not be open on the Internet without at least IP restriction</br>
		<strong>KPI Rule :</strong> For each AWS account AND BE : number of EC2 with public IP and TCP in [22, 3389, 137, 138, 139, 445, 23, 161, 162] from src 0.0.0.0/0 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNWANTEDPORTSPUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:UNWANTEDPORTS_Public</span>
	`,
	EC2InspectorV2MissingScan: `
		<strong>Aim :</strong> Inspector V2 should run on every instance</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 not covered by Inspector V2 scan</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:InspectorV2:MissingScan</span>
`,
	EC2InspectorNeverRun: `
		<strong>Aim :</strong> Inspector should run on every instance, and last run should not be older than 30days</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 without any run found (api limit of 90days)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Inspector:NeverRun</span>
`,
	EC2InspectorTooOld: `
		<strong>Aim :</strong> Inspector should run on every instance, and last run should not be older than 30days</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with last run of inspector older than 30 days.</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Inspector:TooOld</span>
`,
	EC2InspectorUnsupportedRegion: `
		<strong>Aim :</strong> Inspector should run on every instance, and last run should not be older than 30days</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 within regions where Inspector is not supported</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Inspector:NeverRun:UnsupportedRegion</span>
`,
	EC2InspectorGood: `
		<strong>Aim :</strong> Inspector should run on every instance, and last run should not be older than 30days</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with last run of inspector in the last 30 days.</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Inspector:Good</span>
`,
	EC2InspectorGooodWithAgent: `
		<strong>Aim :</strong> Inspector should run on every instance, and last run should not be older than 30days</br>
		<strong>KPI Rule :</strong> For each AWS account : number of EC2 with last run of inspector in the last 30 days with an HEALTHY Agent.</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> INSPECTOR</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">EC2:Inspector:GoodWithAgent</span>
`,
	Inspector: `
		Inspector V2
	`,
	InspectorFindingsCritical: `
		<strong>Aim :</strong> Critical Inspector findings should be corrected</br>
		<strong>KPI Rule :</strong> For BE : number of critical Inspector v2 findings</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0/Default View: 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Inspector:Findings:Critical</span>
	`,
	InspectorFindingsHigh: `
		<strong>Aim :</strong> High Inspector findings should be corrected</br>
		<strong>KPI Rule :</strong> For BE : number of high Inspector v2 findings</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0/Default View: 0</span></br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Inspector:Findings:High</span>
	`,
	CloudFront: `
		CloudFront
	`,
	CloudFrontTotal: `
		CloudFront: number of CloudFronts
	`,
	CloudFrontBadTLS: `
		<strong>Aim :</strong>All CloudFront should support only TLS >= 1.2</br>
		<strong>KPI Rule :</strong> For BE : percentage of CloudFront supporting obsolete versions of TLS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0%(x%)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:BadTLS</span>
	`,
	CloudFrontBadWAF: `
		<strong>Aim :</strong>All CloudFront should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For BE : percentage of CloudFront without WAF or with Bad one</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> WAF</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:WAFAnyVersionValid</span>
	`,
	CloudFrontTLS: `
		<strong>Aim :</strong>All CloudFront should support only TLS >= 1.2</br>
		<strong>KPI Rule :</strong> For each AWS account : number CloudFront supporting obsolete versions of TLS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:TLS</span>
	`,
	CloudFrontWithWAFv2: `
		<strong>Aim :</strong>All CloudFront should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number CloudFront with WAF v2 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:WithWAFv2</span>
	`,
	CloudFrontWithWAFv2Valid: `
		<strong>Aim :</strong>All CloudFront should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number CloudFront with WAF v2 and Valid </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:WithWAFv2Valid</span>
	`,
	CloudFrontWithWAFv2Counting: `
		<strong>Aim :</strong>All CloudFront should have WAF, with Rules, that are blocking</br>
		<strong>KPI Rule :</strong> For each AWS account : number CloudFront with WAF v2 that has rules in Counting mode </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:WithWAFv2Counting</span>
	`,
	CloudFrontWAFAnyVersionValid: `
		<strong>Aim :</strong>All CloudFront should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number CloudFront with WAF Valid (v2) </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAF</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CloudFront:WAFAnyVersionValid</span>
	`,
	PubAppAndAPI: `
		Published Apps (ALB) and API (API Gateway, AppSync) : WAF & TLS
	`,
	PubAppAndAPITotal: `
		Number of PubAppAndAPITotal : number of resources checked ['loadbalancer/app', 'apigateway'] that are "Published" (Accessible on Internet)
	`,
	PubAppAndAPIBadTLS: `
		<strong>Aim :</strong>All Published Apps and API should enforce TLS version >= 1.2</br>
		<strong>KPI Rule :</strong> For BE : percentage of Published Apps and API accepting obsolete versions of TLS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:BadTLS</span>
	`,
	PubAppAndAPIBadWAF: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For BE : percentage of Published Apps and API without WAF or with Bad one</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> WAF</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFAnyVersionValid</span>
	`,
	PubAppAndAPITLS: `
		<strong>Aim :</strong>All Published Apps and API should enforce TLS version >= 1.2</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Published Apps and API accepting obsolete versions of TLS</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:TLS</span>
	`,
	PubAppAndAPINoWAFv2: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number Published Apps and API without WAF v2 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFv2</span>
	`,
	PubAppAndAPIWAFv2: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number Published Apps and API with WAF v2 </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFv2</span>
	`,
	PubAppAndAPIWAFv2Valid: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number Published Apps and API with WAF v2 and Valid </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFv2Valid</span>
	`,
	PubAppAndAPIWAFv2Counting: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are blocking</br>
		<strong>KPI Rule :</strong> For each AWS account : number Published Apps and API with WAF v2 that has rules in Counting mode </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAFv2</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFv2Counting</span>
	`,
	PubAppAndAPIWAFAnyVersionValid: `
		<strong>Aim :</strong>All Published Apps and API should have WAF, with Rules, that are NOT empty</br>
		<strong>KPI Rule :</strong> For each AWS account : number Published Apps and API with WAF Valid (v2) </br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> WAF</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubAppAndAPI:WAFAnyVersionValid</span>
	`,
	PublishedResources: `
		Published Resources : SHIELD
	`,
	PubRessourceTotal: `
		Published Resources : number of resources checked [XLB(ALB/ELB(Classic)), EIP(EC2), CloudFront] that are "Published" (Accessible on Internet)
	`,
	PublishedResourcesBadShield: `
		<strong>Aim :</strong>All Published Resources should be protected by SHIELD which have a valid DRT</br>
		<strong>KPI Rule :</strong> For BE : percentage of PublishedResources with Bad Shield</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> SHIELD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubResource:ShieldAndDRT</span>
	`,
	PubResourceShieldAndDRT: `
		<strong>Aim :</strong>All Published Resources should be protected by SHIELD which have a valid DRT</br>
		<strong>KPI Rule :</strong> For each AWS account : number PublishedResources with Shield and DRT</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> SHIELD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubResource:ShieldAndDRT</span>
	`,
	PubResourceShield: `
		<strong>Aim :</strong>All Published Resources should be protected by SHIELD which have a valid DRT</br>
		<strong>KPI Rule :</strong> For each AWS account : number PublishedResources with Shield</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : x(y)</span></br>
		<strong>Exception:</strong> SHIELD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubResource:Shield</span>
	`,
	PubRessourceNoShield: `
		<strong>Aim :</strong>All Published Resources should be protected by SHIELD which have a valid DRT</br>
		<strong>KPI Rule :</strong> For each AWS account : number PublishedResources without Shield</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> SHIELD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">PubResource:Shield</span>
	`,
	PubResourceShieldTotal: `
		Shield: number of ressource protected (contains [Route53, GlobalAccelerator] that aren't checked yet)
	`,
	Subnet: `
		Subnet
	`,
	SubnetTotal: `
		number of Subnets
	`,
	SubnetDefaultNACL: `
		<strong>Aim :</strong> Verify that NACL are configured (ie not only default one) and each subnet is protected by one NACL</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Default NACL</br>
		For each BE : percentage of Default NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> DEFAULT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Subnet:DefaultNACL</span>
	`,
	SubnetInternetFacing: `
		<strong>Aim :</strong>Check if Subnet Route table (If not found, use Main Route Table of the VPC) have a Route with
		<b><i><strong>Destination:</strong></i></b><i> 0.0.0.0/0 </i><b>AND <i>Target:</i></b><i> ^igw-.*</i></br>
		<strong>KPI Rule :</strong> For each AWS account : number of Internet Facing Subnet</br>
		For each BE : percentage of Internet Facing Subnet</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> INTERNET</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Subnet:InternetFacing</span>
	`,
	SubnetOpenPorts: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each BE : percentage of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Subnet:WithOpenPortsNotBehindSG</span>
	`,
	SubnetWithOpenPortsNotBehindSG: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each AWS account : number of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">Subnet:WithOpenPortsNotBehindSG</span>
	`,
	SubnetPublic: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each BE : percentage of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SubnetPub:WithOpenPortsNotBehindSG</span>
	`,
	SubnetPublicTotal: `
		Subnet with one of these ['PublicIp', 'InternetFacing' 'MapPublicIpOnLaunch'] (Check InternetFacing KPI for more info on that)
	`,
	SubnetPublicWithOpenPortsNotBehindSG: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each AWS account : number of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SubnetPub:WithOpenPortsNotBehindSG</span>
	`,
	SubnetPrivate: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each BE : percentage of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SubnetPriv:WithOpenPortsNotBehindSG</span>
	`,
	SubnetPrivateTotal: `
		number of Subnets with private ip
	`,
	SubnetPrivateWithOpenPortsNotBehindSG: `
		<strong>Aim :</strong> Check that NACL and SG are allowing the same protocol/port</br>
		<strong>KPI Rule :</strong> For each AWS account : number of not compliant NACL</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> OPENPORTS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SubnetPriv:WithOpenPortsNotBehindSG</span>
	`,
	XLB: `
		Elastic Load Balancing
	`,
	XLBtotal: `
		Elastic Load Balancing: number ox XLB
	`,
	XLBUnEncrypted: `
		<strong>Aim :</strong> Check if encrypted with TLS 1.2 or higher (if port not 22 or 80) /!\ Only if encrypted</br>
		<strong>KPI Rule :</strong> For each AWS account & BE : number of bad tls xLB</br>
		<strong>Expected value :</strong> <span class="KPIDefault"> Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">XLB:UnEncryptedTLS</span>
	`,
	XLBBadTLS: `
		<strong>Aim :</strong> Check if encrypted with TLS 1.2 or higher (if port not 22 or 80) /!\ Only if encrypted</br>
		<strong>KPI Rule :</strong> For each AWS account & BE : number of bad tls xLB</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">XLB:UnEncryptedTLS</span>
	`,
	DYNAMODB: `
		DYNAMODB
	`,
	DYNAMODBTotal: `
		Number of Dynamo Db's
	`,
	DYNAMODBEncryption: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of DynamoDB with Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">DYNAMODB:Encryption</span>
	`,
	DYNAMODBNoEncryption: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of DynamoDB with Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTED</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">DYNAMODB:Encryption</span>
	`,
	ELASTIC_SEARCH: `
		ELASTIC SEARCH
	`,
	ELASTIC_SEARCHTotal: `
		number of elastic search
	`,
	ELASTIC_SEARCHBadTLS: `
		<strong>Aim :</strong> TLS Should be 1.2 or higher.</br>
		<strong>KPI Rule :</strong> For each AWS account & BE : number of Elastic search with an old Encryption</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> TLS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:TLS</span>
	`,
	ELASTIC_SEARCHEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of Elastic search with Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:EncryptionAtRest</span>
	`,
	ELASTIC_SEARCHNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Elastic search with Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:EncryptionAtRest</span>
	`,
	ELASTIC_SEARCHEncryptionInTransit: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of Elastic search with Encryption In Transit <strong>Enabled</strong> (DomainEndpointOptions->EnforceHTTPS)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:EncryptionInTransit</span>
	`,
	ELASTIC_SEARCHNoEncryptionInTransit: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Elastic search with Encryption In Transit Disabled (DomainEndpointOptions->EnforceHTTPS)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:EncryptionInTransit</span>
	`,
	ELASTIC_SEARCHNodeToNodeEncryption: `
		<strong>Aim :</strong> Data transferts must be encrypted.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of Elastic search with Node to Node Encryption <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDNODETONODE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:NodeToNodeEncryption</span>
	`,
	ELASTIC_SEARCHNoNodeToNodeEncryption: `
		<strong>Aim :</strong> Data transferts must be encrypted.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Elastic search with Node to Node Encryption Disabled</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDNODETONODE</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTIC_SEARCH:NodeToNodeEncryption</span>
	`,
	ELASTICACHE: `
		ELASTICACHE
	`,
	ELASTICACHETotal: `
		Number of elastic cache
	`,
	ELASTICACHEEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of ELASTICACHE Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTICACHE:EncryptionAtRest</span>
	`,
	ELASTICACHEEncryptionInTransit: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of ELASTICACHE Encryption In Transit <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTICACHE:EncryptionInTransit</span>
	`,
	ELASTICACHENoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of ELASTICACHE Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTICACHE:EncryptionAtRest</span>
	`,
	ELASTICACHENoEncryptionInTransit: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of ELASTICACHE Encryption In Transit Disabled</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">ELASTICACHE:EncryptionInTransit</span>
	`,
	KINESIS: `
		KINESIS
	`,
	KINESISTotal: `
		Number of KINESIS
	`,
	KINESISEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of KINESIS Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">KINESIS:EncryptionAtRest</span>
	`,
	KINESISNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of KINESIS Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">KINESIS:EncryptionAtRest</span>
	`,
	KINESISFIREHOSE: `
		KINESISFIREHOSE
	`,
	KINESISFIREHOSEtotal: `
		Number of KINESISFIREHOSE
	`,
	KINESISFIREHOSEEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of KINESISFIREHOSE Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">KINESISFIREHOSE:EncryptionAtRest</span>
	`,
	KINESISFIREHOSENoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of KINESISFIREHOSE Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">KINESISFIREHOSE:EncryptionAtRest</span>
	`,
	REDSHIFT: `
		REDSHIFT
	`,
	REDSHIFTTotal: `
		Number of REDSHIFT
	`,
	REDSHIFTEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of REDSHIFT with Encryption At Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">REDSHIFT:EncryptionAtRest</span>
	`,
	REDSHIFTNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of REDSHIFT with Encryption At Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">REDSHIFT:EncryptionAtRest</span>
	`,
	REDSHIFTPublic: `
		<strong>Aim :</strong> Nothing should be publicly accessible.</br>
		<strong>KPI Rule :</strong> For each AWS account & BE : number of REDSHIFT Publicly accessible</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> PUBLIC</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">REDSHIFT:Public</span>
	`,
	SAGEMAKER_NOTEBOOKINSTANCE: `
		SAGEMAKER_NOTEBOOKINSTANCE
	`,
	SAGEMAKER_NOTEBOOKINSTANCETotal: `
		Number of SAGEMAKER_NOTEBOOKINSTANCE
	`,
	SAGEMAKER_NOTEBOOKINSTANCEDirectInternetAccess: `
		<strong>Aim :</strong> Nothing should be publicly accessible.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of NotebookInstance with internet access</br>
		For each BE : percentage of NotebookInstance with internet access</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> DIRECTINTERNETACCESS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_NOTEBOOKINSTANCE:DirectInternetAccess</span>
	`,
	SAGEMAKER_NOTEBOOKINSTANCERootAccess: `
		<strong>Aim :</strong> Root access should not be <strong>enabled</strong>.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of NotebookInstance with Root Access <strong>Enabled</strong> for users</br>
		For each BE : percentage of NotebookInstance with Root Access <strong>Enabled</strong> for users</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 0%(x) / Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> ROOTACCESS</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_NOTEBOOKINSTANCE:RootAccess</span>
	`,
	SAGEMAKER_TRAININGJOB: `
		SAGEMAKER_TRAININGJOB
	`,
	SAGEMAKER_TRAININGJOBTotal: `
		Number of SAGEMAKER_TRAININGJOB
	`,
	SAGEMAKER_TRAININGJOBEnableInterContainerTrafficEncryption: `
		<strong>Aim :</strong> Encryption must be <strong>enabled</strong> between Containers.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of SAGEMAKER TRAININGJOB with InterContainerTrafficEncryption <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_TRAININGJOB:EnableInterContainerTrafficEncryption</span>
	`,
	SAGEMAKER_TRAININGJOBNetworkIsolation: `
		<strong>Aim :</strong> Container should have restricted access to network.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of SAGEMAKER TRAININGJOB with NetworkIsolation <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> NONETWORKISOLATION</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_TRAININGJOB:NetworkIsolation</span>
	`,
	SAGEMAKER_TRAININGJOBNoEnableInterContainerTrafficEncryption: `
		<strong>Aim :</strong> Encryption must be <strong>enabled</strong> between Containers.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of SAGEMAKER TRAININGJOB with InterContainerTrafficEncryption Disabled</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_TRAININGJOB:EnableInterContainerTrafficEncryption</span>
	`,
	SAGEMAKER_TRAININGJOBNoNetworkIsolation: `
		<strong>Aim :</strong> Container should have restricted access to network.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of SAGEMAKER TRAININGJOB with NetworkIsolation Disabled</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> NONETWORKISOLATION</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SAGEMAKER_TRAININGJOB:NetworkIsolation</span>
	`,
	SQS: `
		SQS
	`,
	SQSTotal: `
		Number of SQS
	`,
	SQSEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of WORKSPACES with Encryption at Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SQS:EncryptionAtRest</span>
	`,
	SQSNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of WORKSPACES with Encryption at Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">SQS:EncryptionAtRest</span>
	`,
	WORKSPACES: `
		WORKSPACES
	`,
	WORKSPACESTotal: `
		Number of WORKSPACES
	`,
	WORKSPACESEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of WORKSPACES with Encryption at Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionAtRest</span>
	`,
	WORKSPACESEncryptionRoot: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of WORKSPACES with root volume Encryption at Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDROOT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionRoot</span>
	`,
	WORKSPACESEncryptionUser: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of WORKSPACES with user volume Encryption at Rest <strong>Enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDUSER</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionUser</span>
	`,
	WORKSPACESNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of WORKSPACES with Encryption at Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionAtRest</span>
	`,
	WORKSPACESNoEncryptionRoot: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of WORKSPACES with root volume Encryption at Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDROOT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionRoot</span>
	`,
	WORKSPACESNoEncryptionUser: `
		<strong>Aim :</strong> Encryption must be enabled by default.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of WORKSPACES with user volume Encryption at Rest <strong>Disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDUSER</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">WORKSPACES:EncryptionUser</span>
	`,
	BACKUP: `
		BACKUP
	`,
	BACKUPTotal: `
		Number of BACKUP
	`,
	BACKUPNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of BACKUP with encryption at rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">BACKUP:EncryptionAtRest</span>
	`,
	BACKUPEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of BACKUP with encryption at rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">BACKUP:EncryptionAtRest</span>
	`,
	GLUE: `
		GLUE
	`,
	GLUETotal: `
		Number of GLUE
	`,
	GLUENoEncryptionAtRest: `
		<strong>Aim :</strong> DataCatalog should have metadata encrypted.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of GLUE with Encryption at Rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:EncryptionAtRest</span>
	`,
	GLUEEncryptionAtRest: `
		<strong>Aim :</strong> DataCatalog should have metadata encrypted.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of GLUE with Encryption at Rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:EncryptionAtRest</span>
	`,
	GLUENoEncryptionInTransit: `
		<strong>Aim :</strong> Encryption in Transit must be <strong>enabled</strong>.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of GLUE with encryption in Transit disabled (JDBC_ENFORCE_SSL in a Connection)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:EncryptionInTransit</span>
	`,
	GLUEEncryptionInTransit: `
		<strong>Aim :</strong> Encryption in Transit must be <strong>enabled</strong>.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of GLUE with encryption in Transit <strong>enabled</strong> (JDBC_ENFORCE_SSL in a Connection)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:EncryptionInTransit</span>
	`,
	GLUENoConnectionPasswordEncryption: `
		<strong>Aim :</strong> DataCatalog should have connection passwords encrypted.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of GLUE DataCatalog with ConnectionPassword encryption disabled</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDPASSWORD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:ConnectionPasswordEncryption</span>
	`,
	GLUEConnectionPasswordEncryption: `
		<strong>Aim :</strong> DataCatalog should have connection passwords encrypted.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of GLUE DataCatalog with ConnectionPassword encryption <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDPASSWORD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">GLUE:ConnectionPasswordEncryption</span>
	`,
	STORAGEGATEWAY: `
		STORAGEGATEWAY
	`,
	STORAGEGATEWAYTotal: `
		Number of STORAGEGATEWAY
	`,
	STORAGEGATEWAYVolumeTotal: `
		Number of STORAGEGATEWAY Volume
	`,
	STORAGEGATEWAYVolumeNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of STORAGEGATEWAY Volume with Encryption at Rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYVOLUME:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYVolumeEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of STORAGEGATEWAY Volume with Encryption at Rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYVOLUME:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYTapeTotal: `
		Number of STORAGEGATEWAY Tape
	`,
	STORAGEGATEWAYTapeNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of STORAGEGATEWAY Tape with Encryption at Rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYTAPE:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYTapeEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of STORAGEGATEWAY Tape with Encryption at Rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYTAPE:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYShareTotal: `
		Number of STORAGEGATEWAY Share
	`,
	STORAGEGATEWAYShareNoEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of STORAGEGATEWAY Share with Encryption at Rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYShareEncryptionAtRest: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of STORAGEGATEWAY Share with Encryption at Rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:EncryptionAtRest</span>
	`,
	STORAGEGATEWAYShareNoEncryptionInTransit: `
		<strong>Aim :</strong> Encryption in Transit must be <strong>enabled</strong>.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of STORAGEGATEWAY Share with encryption in Transit disabled (MandatoryEncryption in SMBSecurityStrategy)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:EncryptionInTransit</span>
	`,
	STORAGEGATEWAYShareEncryptionInTransit: `
		<strong>Aim :</strong> Encryption in Transit must be <strong>enabled</strong>.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of STORAGEGATEWAY Share with encryption in Transit <strong>enabled</strong> (MandatoryEncryption in SMBSecurityStrategy)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDINTRANSIT</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:EncryptionInTransit</span>
	`,
	STORAGEGATEWAYShareNoGuestPassword: `
		<strong>Aim :</strong> Guest user (smbguest) should have a password to prevent unwanted connection.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of STORAGEGATEWAY Share with password on smbguest (True for SMBGuestPasswordSet)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> GUESTPASSWORD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:GuestPassword</span>
	`,
	STORAGEGATEWAYShareGuestPassword: `
		<strong>Aim :</strong> Guest user (smbguest) should have a password to prevent unwanted connection.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of STORAGEGATEWAY Share with password on smbguest (True for SMBGuestPasswordSet)</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> GUESTPASSWORD</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">STORAGEGATEWAYFILESHARE:GuestPassword</span>
	`,
	XRAY: `
		XRAY
	`,
	XRAYTotal: `
		Number of XRAY
	`,
	XRAYNoEncryption: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each AWS account : number of XRAY with Encryption at Rest <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">XRAY:Encryption</span>
	`,
	XRAYEncryption: `
		<strong>Aim :</strong> Encryption at Rest must be enabled.</br>
		<strong>KPI Rule :</strong> For each BE : percentage of XRAY with Encryption at Rest <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> UNENCRYPTEDATREST</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">XRAY:Encryption</span>
	`,
	VPC: `
		EC2 VPC : Compliant
	`,
	VPCTotal: `
		Number of VPC
	`,
	VPCClean: `
		<strong>Aim :</strong> Default VPC Should not be used, only approved CIDR should be used</br>
		<strong>KPI Rule :</strong> For each BE : percentage of VPC that <strong>aren't</strong> default <strong>AND</strong> <strong>without</strong> '172.31.0.0/16'(Default AWS) in CIDR</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> CLEANING</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">VPC:Clean</span>
	`,
	VPCCompliantIP: `
		<strong>Aim :</strong> Default VPC Should not be used, only approved CIDR should be used</br>
		<strong>KPI Rule :</strong> For each BE : percentage of VPC <strong>with ALL</strong> CIDR in '10.0.0.0/8'</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> COMPLIANTIP</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">VPC:CompliantIP</span>
	`,
	VPCCleaning: `
		<strong>Aim :</strong> Default VPC Should not be used, only approved CIDR should be used</br>
		<strong>KPI Rule :</strong> For each AWS account : number of VPC that <strong>are</strong> default <strong>OR</strong> (inclusive) <strong>with</strong> '172.31.0.0/16'(Default AWS) in CIDR</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> CLEANING</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">VPC:Clean</span>
	`,
	VPCNonCompliantIP: `
		<strong>Aim :</strong> Default VPC Should not be used, only approved CIDR should be used</br>
		<strong>KPI Rule :</strong> For each AWS account : number of VPC <strong>with A</strong> CIDR not in '10.0.0.0/8'</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> COMPLIANTIP</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">VPC:CompliantIP</span>
	`,
	NETWORKFIREWALL: `
		NETWORKFIREWALL : Compliant
	`,
	NETWORKFIREWALLTotal: `
		Number of Network Firewall
	`,
	NETWORKFIREWALLLogging: `
		<strong>Aim :</strong> Network Firewall should have logging enabled and have at least a deny for anyany</br>
		<strong>KPI Rule :</strong> For each BE : percentage of Network Firewall that have logging <strong>enabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> LOGGING</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NETWORKFIREWALL:Logging</span>
	`,
	NETWORKFIREWALLDenyAnyAny: `
		<strong>Aim :</strong> Network Firewall should have logging enabled and have at least a deny for anyany</br>
		<strong>KPI Rule :</strong> For each BE : percentage of Network Firewall that <strong>DENY</strong> any any taffic</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Global View : 100%(x)</span></br>
		<strong>Exception:</strong> FIREWALLANY</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NETWORKFIREWALL:AnyAny</span>
	`,
	NETWORKFIREWALLLoggingDisabled: `
		<strong>Aim :</strong> Network Firewall should have logging enabled and have at least a deny for anyany</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Network Firewall that have logging <strong>disabled</strong></br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> LOGGING</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NETWORKFIREWALL:Logging</span>
	`,
	NETWORKFIREWALLAllowAnyAny: `
		<strong>Aim :</strong> Network Firewall should have logging enabled and have at least a deny for anyany</br>
		<strong>KPI Rule :</strong> For each AWS account : number of Network Firewall that <strong>ALLOW</strong> any any taffic</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View : 0(x)</span></br>
		<strong>Exception:</strong> FIREWALLANY</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">NETWORKFIREWALL:AnyAny</span>
	`,
	CERTIFIEDREPORT: `
		<strong>Aim :</strong> All admins of all accounts should have an AWS Certification (at least Associate level)</br>
		<strong>KPI Rule :</strong> For each AWS account : number of admins without valid certification</br>
		For each BE : Percentage of account not compliant</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View: 0 / Global View : 0%</span></br></br>
		<strong>Exception:</strong> Not Available</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CERTIFIEDREPORT:Score</span>
	`,
	CERTIFIEDREPORTScore: `
		<strong>Aim :</strong> All admins of all accounts should have an AWS Certification (at least Associate level)</br>
		<strong>KPI Rule :</strong> For each AWS account : number of admins without valid certification / For each BE : Percentage of accounts not compliant</br>
		<strong>Expected value :</strong> <span class="KPIDefault">Detailed View: 0 / Global View : 0%</span></br></br>
		<strong>Exception:</strong> Not Available</br>
		<strong>Compliant Name :</strong> <span class="KPIDefault">CERTIFIEDREPORT:Score</span>
	`,
};
