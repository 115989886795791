<template>
  <div>
    <CButton @click="collapse = !collapse" color="info" class="mb-2">
      {{ !collapse ? "SHOW HELP" : "HIDE HELP" }}
    </CButton>
    <span>
      {{ !collapse ? "" : "Click on the KPI to see the corresponding help " }}
    </span>
    <CCollapse :show="collapse">
      <CCardHeader
        ><h3>{{ selectedColumn }}</h3>
      </CCardHeader>
      <CCard body-wrapper align="left" v-if="text">
        <span v-html="text"></span>
      </CCard>
      <CCard body-wrapper align="left" v-else>
        <span> No documentation for this topic</span>
      </CCard>
    </CCollapse>
  </div>
</template>
<script>
export default {
  props: ["text", "selectedColumn"],
  data() {
    return {
      collapse: false
    };
  }
};
</script>