export const getBaseScore = (element, header, valueok) => {
  if (element.Total === 0) {
    return "N/A";
  }
  if (header === "ONE") {
    if (element.Value === null) {
      return "N/A";
    }
    return element.Value === valueok;
  }
  if (header === "CERTIFIEDREPORT") {
    if (element.Score === null) {
      return "N/A";
    }

    return element.Score == 0;
  }
  if (header === "S3") {
    if (element.Encrypted.Value === null && element.EncryptionInTransit.Value && element.Public.Value === null && element.AccessPoint.Public.Value === null) {
      return "N/A";
    }
    if (
      element.Encrypted.Value === 0 &&
      element.EncryptionInTransit.Value === 0 &&
      element.Public.Value === 0 &&
      element.AccessPoint.Public.Value === 0 &&
      element.Encrypted.Value === 0
    ) {
      return "N/A";
    }
    return (
      (element.Encrypted.Value === null || element.Encrypted.Value === 100) &&
      (element.Public.Value === null || element.Public.Value === 0) &&
      (element.AccessPoint.Public.Value === null || element.AccessPoint.Public.Value === 0) &&
      (element.Encrypted.Value !== null || element.Public.Value !== null || element.AccessPoint.Public.Value !== null)
    );
  }
  if (header === "S3-ACCOUNT") {
    if (element.Total === 0 || element.UnEncrypted.Value === null || element.EncryptionInTransit.Value === null || element.Public.Value === null || element.CanBePublic.Value === null) {
      return "N/A";
    }
    if (element.UnEncrypted.Value === 0 && element.EncryptionInTransit.Value === 0 && element.Public.Value === 0 && element.CanBePublic.Value === 0) {
      return true;
    }
    if (
      element.Public.Value !== null &&
      element.CanBePublic.Value !== null &&
      element.EncryptionInTransit.Value !== null &&
      element.UnEncrypted.Value !== null &&
      (element.UnEncrypted.Value !== 0 || element.EncryptionInTransit.Value !== 0 || element.Public.Value !== 0 || element.CanBePublic.Value !== 0)
    ) {
      return false;
    }
  }
  if (header === "GLUE-ACCOUNT") {
    if (
      element.NoEncryptionInTransit.Value === 0 &&
      element.NoEncryptionAtRest.Value === 0 &&
      element.NoConnectionPasswordEncryption.Value === 0
    ) {
      return true;
    }
    if (
      element.NoEncryptionAtRest.Value !== null &&
      element.NoConnectionPasswordEncryption.Value !== null &&
      element.NoEncryptionInTransit.Value !== null &&
      (element.NoEncryptionInTransit.Value !== 0 ||
        element.NoEncryptionAtRest.Value !== 0 ||
        element.NoConnectionPasswordEncryption.Value !== 0)
    ) {
      return false;
    }
    if (
      element.Total === 0 ||
      element.NoEncryptionInTransit.Value === null ||
      element.NoEncryptionAtRest.Value === null ||
      element.NoConnectionPasswordEncryption.Value === null
    ) {
      return "N/A";
    }
  }
  if (header === "STORAGEGATEWAY-ACCOUNT") {
    if (
      element.Total === 0 ||
      element.STORAGEGATEWAYFILESHARE.NoEncryptionInTransit.Value === null ||
      element.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest.Value === null ||
      element.STORAGEGATEWAYFILESHARE.NoGuestPassword.Value === null ||
      element.STORAGEGATEWAYTAPE.NoEncryptionAtRest.Value === null ||
      element.STORAGEGATEWAYVOLUME.NoEncryptionAtRest.Value === null
    ) {
      return "N/A";
    }
    if (
      element.STORAGEGATEWAYFILESHARE.NoEncryptionInTransit.Value === 0 &&
      element.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest.Value === 0 &&
      element.STORAGEGATEWAYFILESHARE.NoGuestPassword.Value === 0 &&
      element.STORAGEGATEWAYTAPE.NoEncryptionAtRest.Value === 0 &&
      element.STORAGEGATEWAYVOLUME.NoEncryptionAtRest.Value === 0
    ) {
      return true;
    }
    if (
      element.STORAGEGATEWAYFILESHARE.NoEncryptionInTransit.Value !== null &&
      element.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest.Value !== null &&
      element.STORAGEGATEWAYFILESHARE.NoGuestPassword.Value !== null &&
      element.STORAGEGATEWAYTAPE.NoEncryptionAtRest.Value !== null &&
      element.STORAGEGATEWAYVOLUME.NoEncryptionAtRest.Value !== null &&
      (element.STORAGEGATEWAYFILESHARE.NoEncryptionInTransit.Value !== 0 ||
        element.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest.Value !== 0 ||
        element.STORAGEGATEWAYFILESHARE.NoGuestPassword.Value !== 0 ||
        element.STORAGEGATEWAYTAPE.NoEncryptionAtRest.Value !== 0 ||
        element.STORAGEGATEWAYVOLUME.NoEncryptionAtRest.Value !== 0)
    ) {
      return false;
    }
  }
  if (header === "CloudFront") {
    if (element.BadTLS.Value === null || element.BadWAF.Value === null) {
      return "N/A";
    }
    return element.BadTLS.Value === 0 && element.BadWAF.Value === 0;
  }
  if (header === "CloudFront-ACCOUNT") {
    if (element.Total === 0 || element.WAFAnyVersionValid.Value === null) {
      return "N/A";
    }
    return element.TLS.Value === 0 && (element.WAFAnyVersionValid.Value + element.WAFAnyVersionValid.Exceptions === element.Total)
  }
  if (header === "WORKSPACES-ACCOUNT") {
    if (element.NoEncryptionRoot.Value === 0 && element.NoEncryptionUser.Value === 0 && element.NoEncryptionAtRest.Value === 0) {
      return true;
    }
    if (
      element.NoEncryptionUser.Value !== null &&
      element.NoEncryptionAtRest.Value !== null &&
      element.NoEncryptionRoot.Value !== null &&
      (element.NoEncryptionRoot.Value !== 0 || element.NoEncryptionUser.Value !== 0 || element.NoEncryptionAtRest.Value !== 0)
    ) {
      return false;
    }
    if (
      element.Total === 0 ||
      element.NoEncryptionRoot.Value === null ||
      element.NoEncryptionUser.Value === null ||
      element.NoEncryptionAtRest.Value === null
    ) {
      return "N/A";
    }
  }
  if (header === "REDSHIFT-ACCOUNT") {
    if (element.Public.Value === 0 && element.NoEncryptionAtRest.Value === 0) {
      return "N/A";
    }
    if (
      element.NoEncryptionAtRest.Value !== null &&
      element.Public.Value !== null &&
      (element.Public.Value !== 0 || element.NoEncryptionAtRest.Value !== 0)
    ) {
      return true;
    }
    if (element.Total === 0 || element.Public.Value === null || element.NoEncryptionAtRest.Value === null) {
      return false;
    }
  }
  if (header === "ELASTICACHE-ACCOUNT") {
    if (element.NoEncryptionInTransit.Value === 0 && element.NoEncryptionAtRest.Value === 0) {
      return true;
    }
    if (
      element.NoEncryptionAtRest.Value !== null &&
      element.NoEncryptionInTransit.Value !== null &&
      (element.NoEncryptionInTransit.Value !== 0 || element.NoEncryptionAtRest.Value !== 0)
    ) {
      return false;
    }
    if (
      element.Total === 0 ||
      element.NoEncryptionInTransit.Value === null ||
      element.NoEncryptionAtRest.Value === null
    ) {
      return "N/A";
    }
  }
  if (header === "ELASTIC_SEARCH-ACCOUNT") {
    if (
      element.NoNodeToNodeEncryption.Value === 0 &&
      element.BadTLS.Value === 0 &&
      element.NoEncryptionInTransit.Value === 0 &&
      element.NoEncryptionAtRest.Value === 0
    ) {
      return true;
    }
    if (
      element.NoEncryptionInTransit.Value !== null &&
      element.NoEncryptionAtRest.Value !== null &&
      element.NoNodeToNodeEncryption.Value !== null &&
      element.BadTLS.Value !== null &&
      (element.NoNodeToNodeEncryption.Value !== 0 ||
        element.BadTLS.Value !== 0 ||
        element.NoEncryptionInTransit.Value !== 0 ||
        element.NoEncryptionAtRest.Value !== 0)
    ) {
      return false;
    }
    if (
      element.Total === 0 ||
      element.NoNodeToNodeEncryption.Value === null ||
      element.BadTLS.Value === null ||
      element.NoEncryptionInTransit.Value === null ||
      element.NoEncryptionAtRest.Value === null
    ) {
      return "N/A";
    }
  }
  if (header === "PubResource-ACCOUNT") {
    if (element.Total !== null && element.ShieldAndDRT.Value + element.ShieldAndDRT.Exceptions === element.Total) {
      return true;
    }
    if (element.Total !== null && element.ShieldAndDRT.Value + element.ShieldAndDRT.Exceptions !== element.Total) {
      return false;
    }
    if (element.Total === 0 || element.ShieldAndDRT.Value === null) {
      return "N/A";
    }
  }
  if (header === "PubAppAndAPI") {
    if (element.BadTLS.Value === null || element.BadWAF.Value === null) {
      return "N/A";
    }
    return element.BadTLS.Value === 0 && element.BadWAF.Value === 0;
  }
  if (header === "PubAppAndAPI-ACCOUNT") {
    if (element.Total !== null) {
      return element.TLS.Value === 0 && element.WAFAnyVersionValid.Value + element.WAFAnyVersionValid.Exceptions === element.Total;
    }
    if (element.Total === 0 || element.WAFAnyVersionValid.Value === null) {
      return "N/A";
    }
  }
  if (header === "IAM") {
    if (
      element.Admin === null &&
      element.Age === null &&
      element.NoBoundary.Value === null &&
      element.Score === null &&
      element.SvcConsole.Value === null &&
      element.User.Value === null
    ) {
      return "N/A";
    }

    return Boolean(
      (element.Admin === null || element.Admin === 0) &&
        (element.Age === null || element.Age === 0) &&
        (element.NoBoundary.Value === null || element.NoBoundary.Value === 0) &&
        (element.Score === null || element.Score === 0) &&
        (element.SvcConsole.Value === null || element.SvcConsole.Value === 0) &&
        (element.User.Value === null || element.User.Value === 0) &&
        (element.Admin !== null ||
          element.Age !== null ||
          element.NoBoundary.Value !== null ||
          element.Score !== null ||
          element.SvcConsole.Value !== null ||
          element.User.Value !== null)
    );
  }
  if (header === "EC2") {
    if (
      element.BadInspector.Value === null &&
      element.Public.Value === null &&
      element.SG00_Private.Value === null &&
      element.SG00_Public.Value === null &&
      element.SSHRDP_Private.Value === null &&
      element.SSHRDP_Public.Value === null &&
      element.UNWANTEDPORTS_Private.Value === null &&
      element.UNWANTEDPORTS_Public.Value === null
    ) {
      return "N/A";
    }
    return (
      Boolean(element.BadInspector.Value === 0 || element.BadInspector.Value === null) &&
      (element.Public.Value === null || element.Public.Value === 0) &&
      (element.SG00_Public.Value === null || element.SG00_Public.Value === 0) &&
      (element.SSHRDP_Public.Value === null || element.SSHRDP_Public.Value === 0) &&
      (element.UNWANTEDPORTS_Public.Value === null || element.UNWANTEDPORTS_Public.Value === 0) &&
      (element.BadInspector.Value !== null ||
        element.Public.Value !== null ||
        element.SG00_Private.Value !== null ||
        element.SG00_Public.Value !== null ||
        element.SSHRDP_Private.Value !== null ||
        element.SSHRDP_Public.Value !== null ||
        element.UNWANTEDPORTS_Private.Value !== null ||
        element.UNWANTEDPORTS_Public.Value !== null)
    );
  }
  if (header === "EC2-Account") {
    if (
      element.Public.Value === null &&
      element.SG00_Public.Value === null &&
      element.SG00_Private.Value === null &&
      element.SSHRDP_Private.Value === null &&
      element.SSHRDP_Public.Value === null &&
      element.UNWANTEDPORTS_Private.Value === null &&
      element.UNWANTEDPORTS_Public.Value === null &&
      element.InspectorV2.MissingScan === null &&
      element.Inspector.NeverRun === null &&
      element.Inspector.TooOld === null &&
      element.Inspector.Good === null &&
      element.Inspector.GooodWithAgent === null
    ) {
      return "N/A";
    }
    return (
      Boolean(element.Inspector.GooodWithAgent.Value === element.InspectorV2.MissingScan.Value ||
        element.Inspector.Good.Value === element.InspectorV2.MissingScan.Value) &&
      (element.Public.Value === null || element.Public.Value === 0) &&
      (element.SG00_Public.Value === null || element.SG00_Public.Value === 0) &&
      (element.SSHRDP_Public.Value === null || element.SSHRDP_Public.Value === 0) &&
      (element.UNWANTEDPORTS_Public.Value === null || element.UNWANTEDPORTS_Public.Value === 0)
    );
  }
  if (header === "Inspector") {
    if (
      element.Findings.Critical === null &&
      element.Findings.High === null
    ) {
      return "N/A";
    }
    return (
      Boolean(element.Findings.Critical === 0 || element.Findings.Critical === null) &&
      (element.Findings.High === null || element.Findings.High === 0)
    );
  }
  if (header === "Inspector-Account") {
    if (
      element.Findings.Critical === null &&
      element.Findings.High === null
    ) {
      return "N/A";
    }
    return (
      Boolean(element.Findings.Critical === 0 || element.Findings.Critical === null) &&
      (element.Findings.High === null || element.Findings.High === 0)
    );
  }
  if (header === "Subnet") {
    if (
      element.DefaultNACL.Value === null &&
      element.OpenPorts.Value === null &&
      element.InternetFacing.Value === null &&
      element.Public.OpenPorts.Value === null &&
      element.Private.OpenPorts.Value === null
    ) {
      return "N/A";
    }
    return (
      (element.DefaultNACL.Value === null || element.DefaultNACL.Value === 0) &&
      (element.Public.OpenPorts.Value === null || element.Public.OpenPorts.Value === 0) &&
      (element.DefaultNACL.Value !== null || element.Public.OpenPorts.Value !== null)
    );
  }
  if (header === "Subnet-Account") {
    if (
      element.DefaultNACL.Value === null &&
      element.InternetFacing.Value === null &&
      element.WithOpenPortsNotBehindSG.Value === null &&
      element.Public.WithOpenPortsNotBehindSG.Value === null &&
      element.Private.WithOpenPortsNotBehindSG.Value === null
    ) {
      return "N/A";
    }
    return (
      (element.DefaultNACL.Value === null || element.DefaultNACL.Value === 0) &&
      (element.Public.WithOpenPortsNotBehindSG.Value === null || element.Public.WithOpenPortsNotBehindSG.Value === 0) &&
      (element.DefaultNACL.Value !== null ||
        element.Public.WithOpenPortsNotBehindSG.Value !== null)
    );
  }
  if (header === "ELASTIC_SEARCH") {
    if (
      element.EncryptionAtRest.Value === null &&
      element.EncryptionInTransit.Value === null &&
      element.NodeToNodeEncryption.Value === null &&
      element.BadTLS.Value === null
    ) {
      return "N/A";
    }
    return (
      (element.EncryptionAtRest.Value === 100 || element.EncryptionAtRest.Value === null) &&
      (element.EncryptionInTransit.Value === null || element.EncryptionInTransit.Value === 100) &&
      (element.NodeToNodeEncryption.Value === null || element.NodeToNodeEncryption.Value === 100) &&
      (element.BadTLS.Value === null || element.BadTLS.Value === 0) &&
      (element.EncryptionAtRest.Value !== null ||
        element.EncryptionInTransit.Value !== null ||
        element.NodeToNodeEncryption.Value !== null ||
        element.BadTLS.Value !== null)
    );
  }
  if (header === "RDS") {
    if (
      (element.Encrypted.Value === null || element.Encrypted.Value === 0) &&
      (element.Public.Value === null || element.Public.Value === 0)
    ) {
      return "N/A";
    }
    if (element.Encrypted.Value === 0 && element.Public.Value === 0) {
      return "N/A";
    }
    return element.Encrypted.Value === 100 && element.Public.Value === 0;
  }
  if (header === "RDS-ACCOUNT") {
    if (element.UnEncrypted.Value === null && element.Public.Value === null) {
      return "N/A";
    }
    return element.UnEncrypted.Value === 0 && element.Public.Value === 0;
  }
  if (header === "ELASTICACHE") {
    if (element.EncryptionAtRest.Value === null && element.EncryptionInTransit.Value === null) {
      return "N/A";
    }
    return (
      (element.EncryptionInTransit.Value === 100 || element.EncryptionInTransit.Value === null) &&
      (element.EncryptionAtRest.Value === null || element.EncryptionAtRest.Value === 100) &&
      (element.EncryptionInTransit.Value !== null || element.EncryptionAtRest.Value !== null)
    );
  }
  if (header === "REDSHIFT") {
    if (element.EncryptionAtRest.Value === null && element.Public.Value === null) {
      return "N/A";
    }
    return (
      (element.Public.Value === 0 || element.Public.Value === null) &&
      (element.EncryptionAtRest.Value === null || element.EncryptionAtRest.Value === 100) &&
      (element.Public.Value !== null || element.EncryptionAtRest.Value !== null)
    );
  }
  if (header === "SAGEMAKER_NOTEBOOKINSTANCE") {
    if (element.DirectInternetAccess.Value === null && element.RootAccess.Value === null) {
      return "N/A";
    }
    return (
      (element.RootAccess.Value === 0 || element.RootAccess.Value === null) &&
      (element.DirectInternetAccess.Value === null || element.DirectInternetAccess.Value === 0) &&
      (element.RootAccess.Value !== null || element.DirectInternetAccess.Value !== null)
    );
  }
  if (header === "SAGEMAKER_NOTEBOOKINSTANCE-Account") {
    if (element.DirectInternetAccess.Value === null && element.RootAccess.Value === null) {
      return "N/A";
    }
    return (
      (element.RootAccess.Value === 0 || element.RootAccess.Value === null) &&
      (element.DirectInternetAccess.Value === null || element.DirectInternetAccess.Value === 0) &&
      (element.RootAccess.Value !== null || element.DirectInternetAccess.Value !== null)
    );
  }
  if (header === "SAGEMAKER_TRAININGJOB") {
    if (element.NetworkIsolation.Value === null && element.EnableInterContainerTrafficEncryption.Value === null) {
      return "N/A";
    }
    return (
      (element.NetworkIsolation.Value === 100 || element.NetworkIsolation.Value === null) &&
      (element.EnableInterContainerTrafficEncryption.Value === null || element.EnableInterContainerTrafficEncryption.Value === 100) &&
      (element.NetworkIsolation.Value !== null || element.EnableInterContainerTrafficEncryption.Value !== null)
    );
  }
  if (header === "SAGEMAKER_TRAININGJOB-ACCOUNT") {
    if (element.NoNetworkIsolation.Value === null && element.NoEnableInterContainerTrafficEncryption.Value === null) {
      return "N/A";
    }
    return (
      (element.NoNetworkIsolation.Value === 100 || element.NoNetworkIsolation.Value === null) &&
      (element.NoEnableInterContainerTrafficEncryption.Value === null || element.NoEnableInterContainerTrafficEncryption.Value === 100) &&
      (element.NoNetworkIsolation.Value !== null || element.NoEnableInterContainerTrafficEncryption.Value !== null)
    );
  }
  if (header === "WORKSPACES") {
    if (element.EncryptionAtRest.Value === null && element.EncryptionUser.Value === null && element.EncryptionRoot.Value === null) {
      return "N/A";
    }
    return (
      (element.EncryptionAtRest.Value === 100 || element.EncryptionAtRest.Value === null) &&
      (element.EncryptionUser.Value === null || element.EncryptionUser.Value === 100) &&
      (element.EncryptionRoot.Value === null || element.EncryptionRoot.Value === 100) &&
      (element.EncryptionAtRest.Value !== null || element.EncryptionRoot.Value !== null || element.EncryptionUser.Value !== null)
    );
  }
  if (header === "GLUE") {
    if (
      element.EncryptionAtRest.Value === null &&
      element.EncryptionInTransit.Value === null &&
      element.ConnectionPasswordEncryption.Value === null
    ) {
      return "N/A";
    }
    return (
      (element.EncryptionAtRest.Value === 100 || element.EncryptionAtRest.Value === null) &&
      (element.EncryptionInTransit.Value === null || element.EncryptionInTransit.Value === 100) &&
      (element.ConnectionPasswordEncryption.Value === null || element.ConnectionPasswordEncryption.Value === 100) &&
      (element.EncryptionAtRest.Value !== null ||
        element.ConnectionPasswordEncryption.Value !== null ||
        element.EncryptionInTransit.Value !== null)
    );
  }
  if (header === "STORAGEGATEWAY") {
    if (
      element.STORAGEGATEWAYFILESHARE.EncryptionAtRest.Value === null &&
      element.STORAGEGATEWAYFILESHARE.EncryptionInTransit.Value === null &&
      element.STORAGEGATEWAYFILESHARE.GuestPassword.Value === null &&
      element.STORAGEGATEWAYVOLUME.EncryptionAtRest.Value === null &&
      element.STORAGEGATEWAYTAPE.EncryptionAtRest.Value === null
    ) {
      return "N/A";
    }
    return (
      (element.STORAGEGATEWAYFILESHARE.EncryptionAtRest.Value === null || element.STORAGEGATEWAYFILESHARE.EncryptionAtRest.Value === 100) &&
      (element.STORAGEGATEWAYFILESHARE.EncryptionInTransit.Value === null ||
        element.STORAGEGATEWAYFILESHARE.EncryptionInTransit.Value === 100) &&
      (element.STORAGEGATEWAYFILESHARE.GuestPassword.Value === null || element.STORAGEGATEWAYFILESHARE.GuestPassword.Value === 100) &&
      (element.STORAGEGATEWAYTAPE.EncryptionAtRest.Value === null || element.STORAGEGATEWAYTAPE.EncryptionAtRest.Value === 100) &&
      (element.STORAGEGATEWAYVOLUME.EncryptionAtRest.Value === null || element.STORAGEGATEWAYVOLUME.EncryptionAtRest.Value === 100) &&
      (element.STORAGEGATEWAYFILESHARE.EncryptionAtRest.Value !== null ||
        element.STORAGEGATEWAYFILESHARE.EncryptionInTransit.Value !== null ||
        element.STORAGEGATEWAYFILESHARE.GuestPassword.Value !== null ||
        element.STORAGEGATEWAYTAPE.EncryptionAtRest.Value !== null ||
        element.STORAGEGATEWAYVOLUME.EncryptionAtRest.Value !== null)
    );
  }
  if (header === "NatGateway") {
    if (
      element.NetworkFirewallNotAttached.Value === null &&
      element.BadNetworkFirewall.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.NetworkFirewallNotAttached.Value === null || element.NetworkFirewallNotAttached.Value === 0) &&
      (element.BadNetworkFirewall.Value === null || element.BadNetworkFirewall.Value === 0) &&
      (element.BadNetworkFirewall.Value !== null ||
        element.NetworkFirewallNotAttached.Value !== null)
    );
  }
  if (header === "NatGateway-Account") {
    if (
      element.Total.Value === null &&
      element.NETWORKFIREWALL.BadNetworkFirewall.Value === null &&
      element.NETWORKFIREWALL.NotAttached.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.NETWORKFIREWALL.NotAttached.Value === null || element.NETWORKFIREWALL.NotAttached.Value === 0) &&
      (element.NETWORKFIREWALL.BadNetworkFirewall.Value === null || element.NETWORKFIREWALL.BadNetworkFirewall.Value === 0) &&
      (element.NETWORKFIREWALL.BadNetworkFirewall.Value !== null ||
        element.NETWORKFIREWALL.NotAttached.Value !== null)
    );
  }
  if (header === "VPC") {
    if (
      element.Clean.Value === null &&
      element.CompliantIP.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.Clean.Value === null || element.Clean.Value === 100) &&
      (element.CompliantIP.Value === null || element.CompliantIP.Value === 100) &&
      (element.Clean.Value !== null ||
        element.CompliantIP.Value !== null)
    );
  }
  if (header === "VPC-Account") {
    if (
      element.Total.Value === null &&
      element.Cleaning.Value === null &&
      element.NonCompliantIP.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.Cleaning.Value === null || element.Cleaning.Value === 0) &&
      (element.NonCompliantIP.Value === null || element.NonCompliantIP.Value === 0) &&
      (element.NonCompliantIP.Value !== null ||
        element.Cleaning.Value !== null)
    );
  }
  if (header === "NETWORKFIREWALL") {
    if (
      element.Logging.Value === null &&
      element.DenyAnyAny.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.Logging.Value === null || element.Logging.Value === 100) &&
      (element.DenyAnyAny.Value === null || element.DenyAnyAny.Value === 100) &&
      (element.DenyAnyAny.Value !== null ||
        element.Logging.Value !== null)
    );
  }
  if (header === "NETWORKFIREWALL-Account") {
    if (
      element.Total.Value === null &&
      element.LoggingDisabled.Value === null &&
      element.AllowAnyAny.Value === null) {
      return "N/A";
    }
    return (
      Boolean(element.AllowAnyAny.Value === null || element.AllowAnyAny.Value === 0) &&
      (element.LoggingDisabled.Value === null || element.LoggingDisabled.Value === 0) &&
      (element.LoggingDisabled.Value !== null ||
        element.AllowAnyAny.Value !== null)
    );
  }
};
