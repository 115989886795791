export const formatException = (value, exc) => {
  let result = "";
  if (value === null) {
    result = "N/A";
  } else {
    result = result + value;
  }
  if (exc !== null && exc !== 0) {
    if (exc === 1) {
      result = result + " and " + exc + " exception";
    } else {
      result = result + " and " + exc + " exception(s)";
    }
  }
  return result;
};
export const formatExceptionShort = (value, exc) => {
  let result = "";
  if (value === null && exc === null) {
    result = "N/A";
  } else if (value !== null) {
    result = result + value;
  }
  if (exc !== null && exc !== 0) {
    if (value === null) {
      result = "0(" + exc + ")";
    } else {
      result = result + "(" + exc + ")";
    }
  }
  return result;
};

export const insertDetailsFields = (detail, fields, toggle, detailsFields) => {
  const result = [];
  const index = fields.findIndex((x) => x.key === detail);

  if (toggle) {
    fields.splice(index + 1, detailsFields.length);
  } else {
    fields = insert(fields, index + 1, detailsFields);
  }
  return fields;
};

const insert = (arr, index, newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];
