<template>
  <td class="py-2" v-if="score === 'N/A'"></td>
  <td class="py-2" v-else-if="isTotal">
    {{ formatData ? formatData + unit : formatData }}
  </td>
  <td class="py-2" v-else>
    <CBadge
      :color="check ? 'success' : 'danger'"
      v-if="formatData !== 'N/A' || formatData !== null || formatData !== ''"
      >{{ formatData ? formatData + unit : formatData }}</CBadge
    >
  </td>
</template>
<script>
import { formatExceptionShort } from "../helpers.js";
export default {
  props: {
    data: [Object, String, Number],
    FormatValueExceptions: Boolean,
    check: [Boolean, Number],
    unit: { type: String, default: "" },
    isTotal: { type: Boolean, default: false },
    score: { type: [Boolean, String], default: "ok" }
  },
  computed: {
    formatData() {
      if (this.FormatValueExceptions) {
        return formatExceptionShort(this.data.Value, this.data.Exceptions);
      }
      return this.data === null ? "N/A" : this.data;
    }
  }
};
</script>