<template>
  <td class="py-2">
    <CButton
      v-if="score === 'N/A'"
      size="sm"
      variant="outline"
      color="info"
      height="0.8rem"
    >
      {{ "N/A" }}
    </CButton>
    <CButton
      v-else
      size="sm"
      :color="score ? 'success' : 'danger'"
      height="0.8rem"
      @click="$emit('show-hide')"
    >
      {{ Boolean(toggle) ? "Hide" : score ? "V" : "X" }}
    </CButton>
  </td>
</template>
<script>
export default {
  props: ["score", "toggle"],
  emits: ["show-hide"],
};
</script>