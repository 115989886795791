export const defaultFields = [
  {
    key: "ToAccounts",
    label: "Accounts",
    sorter: false,
    filter: false,
    _style: "min-width:20px",
  },
  {
    key: "Score",
    _style: "min-width:20px",
  },
  {
    key: "ScopeId",
    label: "Scope",
  },
  {
    key: "SubScopeId",
    label: "Sub-scope",
  },
  {
    key: "BE",
    _style: "min-width:300px;position: -webkit-sticky !important;position: sticky !important;left: 0px;background-color: #fff;z-index: 10;background-clip: padding-box;",
  },
  {
    key: "OrgId",
  },
  {
    key: "NbAccount",
  },
  {
    key: "MFA",
    label: "MFA",
  },
  {
    key: "CT",
    label: "CT",
  },
  {
    key: "TA",
    label: "TA",
  },
  {
    key: "IAM",
    label: "IAM",
    sorter: false,
    filter: false,
  },
  {
    key: "S3",
    label: "S3",
    sorter: false,
    filter: false,
  },
  {
    key: "RDS",
    label: "RDS",
    sorter: false,
    filter: false,
  },
  {
    key: "EBS",
    label: "EBS",
    sorter: false,
    filter: false,
  },
  {
    key: "EFS",
    label: "EFS",
    sorter: false,
    filter: false,
  },
  {
    key: "FSX",
    label: "FSX",
    sorter: false,
    filter: false,
  },
  {
    key: "NatGateway",
    label: "NatGateway",
    sorter: false,
    filter: false,
  },
  {
    key: "EC2",
    label: "EC2",
    sorter: false,
    filter: false,
  },
  {
    key: "Inspector",
    label: "Inspector",
    sorter: false,
    filter: false,
  },
  {
    key: "CloudFront",
    label: "CloudFront",
    sorter: false,
    filter: false,
  },
  {
    key: "PubAppAndAPI",
    label: "PubApp And API",
    sorter: false,
    filter: false,
  },
  {
    key: "PublishedResources",
    label: "Published Resources",
    sorter: false,
    filter: false,
  },
  {
    key: "Subnet",
    label: "Subnet",
    sorter: false,
    filter: false,
  },
  {
    key: "XLB",
    label: "XLB",
    sorter: false,
    filter: false,
  },
  {
    key: "DYNAMODB",
    label: "DYNAMODB",
    sorter: false,
    filter: false,
  },
  {
    key: "ELASTIC_SEARCH",
    label: "ELASTIC_SEARCH",
  },
  {
    key: "ELASTICACHE",
    label: "ELASTICACHE",
    sorter: false,
    filter: false,
  },
  {
    key: "KINESIS",
    label: "KINESIS",
    sorter: false,
    filter: false,
  },
  {
    key: "KINESISFIREHOSE",
    label: "KINESIS FIREHOSE",
    sorter: false,
    filter: false,
  },
  {
    key: "REDSHIFT",
    label: "REDSHIFT",
    sorter: false,
    filter: false,
  },
  {
    key: "SAGEMAKER_NOTEBOOKINSTANCE",
    label: "SAGEMAKER NOTEBOOKINSTANCE",
    sorter: false,
    filter: false,
  },
  {
    key: "SAGEMAKER_TRAININGJOB",
    label: "SAGEMAKER TRAININGJOB",
    sorter: false,
    filter: false,
  },
  {
    key: "SQS",
    label: "SQS",
    sorter: false,
    filter: false,
  },
  {
    key: "WORKSPACES",
    label: "WORKSPACES",
    sorter: false,
    filter: false,
  },
  {
    key: "BACKUP",
    label: "BACKUP",
    sorter: false,
    filter: false,
  },
  {
    key: "GLUE",
    label: "GLUE",
    sorter: false,
    filter: false,
  },
  {
    key: "STORAGEGATEWAY",
    label: "STORAGEGATEWAY",
    sorter: false,
    filter: false,
  },
  {
    key: "XRAY",
    label: "XRAY",
    sorter: false,
    filter: false,
  },
  {
    key: "VPC",
    label: "VPC",
    sorter: false,
    filter: false,
  },
  {
    key: "NETWORKFIREWALL",
    label: "NETWORKFIREWALL",
    sorter: false,
    filter: false,
  },

  {
    key: "CERTIFIEDREPORT",
    label: "CERTIFIEDREPORT",
    sorter: false,
    filter: false,
  },
];

export const AccountFields = [
  {
    key: "Score",
  },
  {
    key: "AccountId",
    _style: "min-width:20px",
  },
  {
    key: "ScopeId",
    label: "Scope",
  },
  {
    key: "SubScopeId",
    label: "Sub-scope",
  },
  {
    key: "BE",
  },
  {
    key: "OrgId",
  },
  {
    key: "AccountAlias",
    _style: "min-width:300px;position: -webkit-sticky !important;position: sticky !important;left: 0px;background-color: #fff;z-index: 10;background-clip: padding-box;",
  },
  // { key: "NbAccount" },
  {
    key: "MFA",
    label: "MFA",
  },
  {
    key: "CT",
    label: "CT",
  },
  {
    key: "TA",
    label: "TA",
  },
  {
    key: "IAM",
    label: "IAM",
    sorter: false,
    filter: false,
  },
  {
    key: "S3",
    label: "S3",
    sorter: false,
    filter: false,
  },
  {
    key: "RDS",
    label: "RDS",
    sorter: false,
    filter: false,
  },
  {
    key: "EBS",
    label: "EBS",
    sorter: false,
    filter: false,
  },
  {
    key: "EFS",
    label: "EFS",
    sorter: false,
    filter: false,
  },
  {
    key: "FSX",
    label: "FSX",
    sorter: false,
    filter: false,
  },
  {
    key: "NatGateway",
    label: "NatGateway",
    sorter: false,
    filter: false,
  },
  {
    key: "EC2",
    label: "EC2",
    sorter: false,
    filter: false,
  },
  {
    key: "Inspector",
    label: "Inspector",
    sorter: false,
    filter: false,
  },
  {
    key: "XLB",
    label: "XLB",
    sorter: false,
    filter: false,
  },
  {
    key: "Subnet",
    label: "Subnet",
    sorter: false,
    filter: false,
  },
  {
    key: "CloudFront",
    label: "CloudFront",
    sorter: false,
    filter: false,
  },
  {
    key: "PubResource",
    label: "Published Resources",
    sorter: false,
    filter: false,
  },
  {
    key: "PubAppAndAPI",
    label: "PubApp And API",
    sorter: false,
    filter: false,
  },
  {
    key: "DYNAMODB",
    label: "DYNAMODB",
    sorter: false,
    filter: false,
  },
  {
    key: "ELASTIC_SEARCH",
    label: "ELASTIC_SEARCH",
    sorter: false,
    filter: false,
  },
  {
    key: "ELASTICACHE",
    label: "ELASTICACHE",
    sorter: false,
    filter: false,
  },
  {
    key: "KINESIS",
    label: "KINESIS",
    sorter: false,
    filter: false,
  },
  {
    key: "KINESISFIREHOSE",
    label: "KINESIS FIREHOSE",
    sorter: false,
    filter: false,
  },
  {
    key: "REDSHIFT",
    label: "REDSHIFT",
    sorter: false,
    filter: false,
  },
  {
    key: "SAGEMAKER_NOTEBOOKINSTANCE",
    label: "SAGEMAKER NOTEBOOKINSTANCE",
    sorter: false,
    filter: false,
  },
  {
    key: "SAGEMAKER_TRAININGJOB",
    label: "SAGEMAKER TRAININGJOB",
    sorter: false,
    filter: false,
  },
  {
    key: "SQS",
    label: "SQS",
    sorter: false,
    filter: false,
  },
  {
    key: "WORKSPACES",
    label: "WORKSPACES",
    sorter: false,
    filter: false,
  },
  {
    key: "BACKUP",
    label: "BACKUP",
    sorter: false,
    filter: false,
  },
  {
    key: "GLUE",
    label: "GLUE",
    sorter: false,
    filter: false,
  },
  {
    key: "STORAGEGATEWAY",
    label: "STORAGEGATEWAY",
    sorter: false,
    filter: false,
  },
  {
    key: "XRAY",
    label: "XRAY",
    sorter: false,
    filter: false,
  },
  {
    key: "VPC",
    label: "VPC",
    sorter: false,
    filter: false,
  },
  {
    key: "NETWORKFIREWALL",
    label: "NETWORKFIREWALL",
    sorter: false,
    filter: false,
  },
  {
    key: "CERTIFIEDREPORT",
    label: "CERTIFIEDREPORT",
    sorter: false,
    filter: false,
  },
];

export const detailsFields = {
  EC2: [
    {
      key: "EC2-BadInspector",
      label: "Bad Inspector",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SG00_Public",
      label: "SG00_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SG00_Private",
      label: "SG00_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSHRDP_Public",
      label: "SSHRDP_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSHRDP_Private",
      label: "SSHRDP_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-UNWANTEDPORTS_Public",
      label: "UNWANTEDPORTS_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-UNWANTEDPORTS_Private",
      label: "UNWANTEDPORTS_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Metadata-IMDSv2NotEnforced",
      label: "IMDSv2 Not Enforced",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSMAgent",
      label: "Not managed by SSM",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Permissions",
      label: "Too many rights",
      sorter: false,
      filter: false,
    },
  ],
  Inspector: [
    {
      key: "Inspector-Findings-Critical",
      label: "Critical findings",
      sorter: false,
      filter: false,
    },
    {
      key: "Inspector-Findings-High",
      label: "High findings",
      sorter: false,
      filter: false,
    },
  ],
  ELASTICACHE: [
    {
      key: "ELASTICACHE-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTICACHE-EncryptionInTransit",
      label: "EncryptionInTransit",
      sorter: false,
      filter: false,
    },
  ],
  ELASTIC_SEARCH: [
    {
      key: "ELASTIC_SEARCH-BadTLS",
      label: "BadTLS",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-EncryptionInTransit",
      label: "EncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-NodeToNodeEncryption",
      label: "NodeToNodeEncryption",
      sorter: false,
      filter: false,
    },
  ],
  GLUE: [
    {
      key: "GLUE-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "GLUE-EncryptionInTransit",
      label: "EncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "GLUE-ConnectionPasswordEncryption",
      label: "ConnectionPasswordEncryption",
      sorter: false,
      filter: false,
    },
  ],
  IAM: [
    {
      key: "IAM-USER",
      label: "Users",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-ADMIN",
      label: "Admin",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-AGE",
      label: "Age",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-SCORE",
      label: "Score",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-NOBOUNDARY",
      label: "NoBoundary",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-SVCCONSOLE",
      label: "SvcConsole",
      sorter: false,
      filter: false,
    },
  ],
  RDS: [
    {
      key: "RDS-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "RDS-Encrypted",
      label: "Encrypted",
      sorter: false,
      filter: false,
    },
  ],
  REDSHIFT: [
    {
      key: "REDSHIFT-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "REDSHIFT-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
  ],
  S3: [
    {
      key: "S3-Encrypted",
      label: "Encrypted",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-EncryptionInTransit",
      label: "Encryption in transit",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-AccessPoint",
      label: "AccessPoint",
      sorter: false,
      filter: false,
    },
  ],
  SAGEMAKER_NOTEBOOKINSTANCE: [
    {
      key: "SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess",
      label: "DirectInternetAccess",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_NOTEBOOKINSTANCE-RootAccess",
      label: "RootAccess",
      sorter: false,
      filter: false,
    },
  ],
  ///////MANQUE UN
  SAGEMAKER_TRAININGJOB: [
    {
      key: "SAGEMAKER_TRAININGJOB-EnableInterContainerTrafficEncryption",
      label: "EnableInterContainerTrafficEncryption",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_TRAININGJOB-NetworkIsolation",
      label: "NetworkIsolation",
      sorter: false,
      filter: false,
    },
  ],
  Subnet: [
    {
      key: "Subnet-DefaultNACL",
      label: "DefaultNACL",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-InternetFacing",
      label: "InternetFacing",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-OpenPorts",
      label: "OpenPorts",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Private",
      label: "Private",
      sorter: false,
      filter: false,
    },
  ],
  STORAGEGATEWAY: [
    {
      key: "STORAGEGATEWAY-VOL-EncryptionAtRest",
      label: "Volume EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-TAPE-EncryptionAtRest",
      label: "Tape EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-EncryptionAtRest",
      label: "Share EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-EncryptionInTransit",
      label: "Share EncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-GuestPassword",
      label: " Share GuestPassword",
      sorter: false,
      filter: false,
    },
  ],
  WORKSPACES: [
    {
      key: "WORKSPACES-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "WORKSPACES-EncryptionRoot",
      label: "EncryptionRoot",
      sorter: false,
      filter: false,
    },
    {
      key: "WORKSPACES-EncryptionUser",
      label: "EncryptionUser",
      sorter: false,
      filter: false,
    },
  ],
  EBS: [
    {
      key: "EBS-Encrypted",
      label: "Encrypted",
      sorter: false,
      filter: false,
    },
  ],
  EFS: [
    {
      key: "EFS-Encrypted",
      label: "Encrypted",
      sorter: false,
      filter: false,
    },
  ],
  FSX: [
    {
      key: "FSX-Encrypted",
      label: "Encrypted",
      sorter: false,
      filter: false,
    },
  ],
  NatGateway: [
    {
      key: "NatGateway-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "NatGateway-NetworkFirewallNotAttached",
      label: "NetworkFirewallNotAttached",
      sorter: false,
      filter: false,
    },
    {
      key: "NatGateway-BadNetworkFirewall",
      label: "BadNetworkFirewall",
      sorter: false,
      filter: false,
    },
  ],
  XLB: [
    {
      key: "XLB-BadTLS",
      label: "BadTLS",
      sorter: false,
      filter: false,
    },
  ],
  CloudFront: [
    {
      key: "CloudFront-BadTLS",
      label: "Bad TLS",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-BadWAF",
      label: "Bad Waf",
      sorter: false,
      filter: false,
    },
  ],
  PublishedResources: [
    {
      key: "PublishedResources-BadShield",
      label: "Bad Shield",
      sorter: false,
      filter: false,
    },
  ],
  PubAppAndAPI: [
    {
      key: "PubAppAndAPI-BadTLS",
      label: "BadTLS",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-BadWAF",
      label: "BadWAF",
      sorter: false,
      filter: false,
    },
  ],
  DYNAMODB: [
    {
      key: "DYNAMODB-Encryption",
      label: "Encryption",
      sorter: false,
      filter: false,
    },
  ],
  KINESIS: [
    {
      key: "KINESIS-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  KINESISFIREHOSE: [
    {
      key: "KINESISFIREHOSE-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  SQS: [
    {
      key: "SQS-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  BACKUP: [
    {
      key: "BACKUP-EncryptionAtRest",
      label: "EncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  XRAY: [
    {
      key: "XRAY-Encryption",
      label: "Encryption",
      sorter: false,
      filter: false,
    },
  ],
  VPC: [
    {
      key: "VPC-Clean",
      label: "Clean",
      sorter: false,
      filter: false,
    },
    {
      key: "VPC-CompliantIP",
      label: "CompliantIP",
      sorter: false,
      filter: false,
    },
  ],
  NETWORKFIREWALL: [
    {
      key: "NETWORKFIREWALL-Logging",
      label: "Logging",
      sorter: false,
      filter: false,
    },
    {
      key: "NETWORKFIREWALL-DenyAnyAny",
      label: "DenyAnyAny",
      sorter: false,
      filter: false,
    },
  ],
};

export const AccountDetailsFields = {
  CERTIFIEDREPORT: [
    {
      key: "CERTIFIEDREPORT-Score",
      label: "Score",
      sorter: false,
      filter: false,
    },
  ],
  EC2: [
    {
      key: "EC2-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-AutoscaledVMs",
      label: "AutoscaledVMs",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SG00_Public",
      label: "SG00_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SG00_Private",
      label: "SG00_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSHRDP_Private",
      label: "SSHRDP_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSHRDP_Public",
      label: "SSHRDP_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-UNWANTEDPORTS_Private",
      label: "UNWANTEDPORTS_Private",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-UNWANTEDPORTS_Public",
      label: "UNWANTEDPORTS_Public",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Metadata-IMDSv2NotEnforced",
      label: "IMDSv2 Not Enforced",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSMAgent",
      label: "Not managed by SSM",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSMAgent-NotInstalled",
      label: "SSM agent not installed",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSMAgent-NotReachable",
      label: "SSM agent not reachable",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-SSMAgent-NotUptodate",
      label: "SSM agent not uptodate",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Permissions",
      label: "Too many rights",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Permissions-Admin",
      label: "With admin rights",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Permissions-PowerUser",
      label: "With PowerUser rights",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-InspectorV2-MissingScan",
      label: "No Inspector v2",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Inspector-UnsupportedRegion",
      label: "Inspector vX Not Supported",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Inspector-NeverRun",
      label: "Inspector v1 Never Run",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Inspector-TooOld",
      label: "Inspector v1 Too Old",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Inspector-Good",
      label: "Inspector v1 Good",
      sorter: false,
      filter: false,
    },
    {
      key: "EC2-Inspector-GooodWithAgent",
      label: "Inspector v1 GooodWithAgent",
      sorter: false,
      filter: false,
    },
  ],
  Inspector: [
    {
      key: "Inspector-Findings-Critical",
      label: "Critical findings",
      sorter: false,
      filter: false,
    },
    {
      key: "Inspector-Findings-High",
      label: "High findings",
      sorter: false,
      filter: false,
    },
  ],
  ELASTICACHE: [
    {
      key: "ELASTICACHE-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTICACHE-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTICACHE-NoEncryptionInTransit",
      label: "NoEncryptionInTransit",
      sorter: false,
      filter: false,
    },
  ],
  ELASTIC_SEARCH: [
    {
      key: "ELASTIC_SEARCH-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-BadTLS",
      label: "BadTLS",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-NoEncryptionInTransit",
      label: "NoEncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "ELASTIC_SEARCH-NoNodeToNodeEncryption",
      label: "NoNodeToNodeEncryption",
      sorter: false,
      filter: false,
    },
  ],
  GLUE: [
    {
      key: "GLUE-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "GLUE-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "GLUE-NoEncryptionInTransit",
      label: "NoEncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "GLUE-NoConnectionPasswordEncryption",
      label: "NoConnectionPasswordEncryption",
      sorter: false,
      filter: false,
    },
  ],
  IAM: [
    {
      key: "IAM-USER",
      label: "Users",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-ADMIN",
      label: "Admin",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-AGE",
      label: "Age",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-SCORE",
      label: "Score",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-NOBOUNDARY",
      label: "NoBoundary",
      sorter: false,
      filter: false,
    },
    {
      key: "IAM-SVCCONSOLE",
      label: "SvcConsole",
      sorter: false,
      filter: false,
    },
  ],
  RDS: [
    {
      key: "RDS-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "RDS-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "RDS-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
  ],
  REDSHIFT: [
    {
      key: "REDSHIFT-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "REDSHIFT-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "REDSHIFT-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
  ],
  S3: [
    {
      key: "S3-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-EncryptionInTransit",
      label: "Encryption in transit",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-Public",
      label: "Public",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-CanBePublic",
      label: "CanBePublic",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-AccessPoint-Total",
      label: " AccessPoint Total",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-AccessPoint-Public",
      label: "AccessPoint Public",
      sorter: false,
      filter: false,
    },
    {
      key: "S3-AccessPoint-CanBePublic",
      label: "AccessPoint CanBePublic",
      sorter: false,
      filter: false,
    },
  ],
  SAGEMAKER_NOTEBOOKINSTANCE: [
    {
      key: "SAGEMAKER_NOTEBOOKINSTANCE-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess",
      label: "DirectInternetAccess",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_NOTEBOOKINSTANCE-RootAccess",
      label: "RootAccess",
      sorter: false,
      filter: false,
    },
  ],
  SAGEMAKER_TRAININGJOB: [
    {
      key: "SAGEMAKER_TRAININGJOB-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_TRAININGJOB-NoEnableInterContainerTrafficEncryption",
      label: "NoEnableInterContainerTrafficEncryption",
      sorter: false,
      filter: false,
    },
    {
      key: "SAGEMAKER_TRAININGJOB-NoNetworkIsolation",
      label: "NoNetworkIsolation",
      sorter: false,
      filter: false,
    },
  ],
  Subnet: [
    {
      key: "Subnet-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-DefaultNACL",
      label: "DefaultNACL",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-InternetFacing",
      label: "InternetFacing",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-WithOpenPortsNotBehindSG",
      label: "WithOpenPortsNotBehindSG",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Public-Total",
      label: "Public Total",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Public-WithOpenPortsNotBehindSG",
      label: "Public WithOpenPortsNotBehindSG",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Private-Total",
      label: "Private Total",
      sorter: false,
      filter: false,
    },
    {
      key: "Subnet-Private-WithOpenPortsNotBehindSG",
      label: "Private WithOpenPortsNotBehindSG",
      sorter: false,
      filter: false,
    },
  ],
  STORAGEGATEWAY: [
    {
      key: "STORAGEGATEWAY-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-VOL-NoEncryptionAtRest",
      label: "Volume NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-VOL-Total",
      label: "Volume Total",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-TAPE-NoEncryptionAtRest",
      label: "Tape NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-TAPE-Total",
      label: "Tape Total",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-Total",
      label: "Share Total",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-NoEncryptionAtRest",
      label: "Share NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-NoEncryptionInTransit",
      label: "Share NoEncryptionInTransit",
      sorter: false,
      filter: false,
    },
    {
      key: "STORAGEGATEWAY-SHARE-NoGuestPassword",
      label: " Share NoGuestPassword",
      sorter: false,
      filter: false,
    },
  ],
  WORKSPACES: [
    {
      key: "WORKSPACES-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "WORKSPACES-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
    {
      key: "WORKSPACES-NoEncryptionRoot",
      label: "NoEncryptionRoot",
      sorter: false,
      filter: false,
    },
    {
      key: "WORKSPACES-NoEncryptionUser",
      label: "NoEncryptionUser",
      sorter: false,
      filter: false,
    },
  ],
  EBS: [
    {
      key: "EBS-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "EBS-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
  ],
  EFS: [
    {
      key: "EFS-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "EFS-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
  ],
  FSX: [
    {
      key: "FSX-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "FSX-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
  ],
  NatGateway: [
    {
      key: "NatGateway-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "NatGateway-NETWORKFIREWALL-NotAttached",
      label: "NotAttachedToFirewall",
      sorter: false,
      filter: false,
    },
    {
      key: "NatGateway-NETWORKFIREWALL-BadNetworkFirewall",
      label: "BadNetworkFirewall",
      sorter: false,
      filter: false,
    },
  ],
  XLB: [
    {
      key: "XLB-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "XLB-UnEncrypted",
      label: "UnEncrypted",
      sorter: false,
      filter: false,
    },
  ],
  CloudFront: [
    {
      key: "CloudFront-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-TLS",
      label: "TLS<1.2",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-WithWAFv2",
      label: "WithWAFv2",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-WithWAFv2Valid",
      label: "WithWAFv2Valid",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-WithWAFv2Counting",
      label: "WithWAFv2Counting",
      sorter: false,
      filter: false,
    },
    {
      key: "CloudFront-WAFAnyVersionValid",
      label: "WAFAnyVersionValid",
      sorter: false,
      filter: false,
    },
  ],
  PubResource: [
    {
      key: "PubResource-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "PubResource-ShieldAndDRT",
      label: "ShieldAndDRT",
      sorter: false,
      filter: false,
    },
    {
      key: "PubResource-Shield",
      label: "Shield",
      sorter: false,
      filter: false,
    },
    {
      key: "PubResource-NoShield",
      label: "NoShield",
      sorter: false,
      filter: false,
    },
    {
      key: "PubResource-ShieldTotal",
      label: "ShieldTotal",
      sorter: false,
      filter: false,
    },
  ],
  PubAppAndAPI: [
    {
      key: "PubAppAndAPI-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-TLS",
      label: "TLS<1.2",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-NoWAFv2",
      label: "NoWAFv2",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-WAFv2",
      label: "WAFv2",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-WAFv2Valid",
      label: "WAFv2Valid",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-WAFv2Counting",
      label: "WAFv2Counting",
      sorter: false,
      filter: false,
    },
    {
      key: "PubAppAndAPI-WAFAnyVersionValid",
      label: "WAFAnyVersionValid",
      sorter: false,
      filter: false,
    },
  ],
  DYNAMODB: [
    {
      key: "DYNAMODB-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "DYNAMODB-NoEncryption",
      label: "NoEncryption",
      sorter: false,
      filter: false,
    },
  ],
  KINESIS: [
    {
      key: "KINESIS-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "KINESIS-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  KINESISFIREHOSE: [
    {
      key: "KINESISFIREHOSE-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "KINESISFIREHOSE-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  SQS: [
    {
      key: "SQS-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "SQS-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  BACKUP: [
    {
      key: "BACKUP-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "BACKUP-NoEncryptionAtRest",
      label: "NoEncryptionAtRest",
      sorter: false,
      filter: false,
    },
  ],
  XRAY: [
    {
      key: "XRAY-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "XRAY-NoEncryption",
      label: "NoEncryption",
      sorter: false,
      filter: false,
    },
  ],
  VPC: [
    {
      key: "VPC-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "VPC-Cleaning",
      label: "Cleaning",
      sorter: false,
      filter: false,
    },
    {
      key: "VPC-NonCompliantIP",
      label: "NonCompliantIP",
      sorter: false,
      filter: false,
    },
  ],
  NETWORKFIREWALL: [
    {
      key: "NETWORKFIREWALL-Total",
      label: "Total",
      sorter: false,
      filter: false,
    },
    {
      key: "NETWORKFIREWALL-LoggingDisabled",
      label: "LoggingDisabled",
      sorter: false,
      filter: false,
    },
    {
      key: "NETWORKFIREWALL-AllowAnyAny",
      label: "AllowAnyAny",
      sorter: false,
      filter: false,
    },
  ],
};
